<template>
  <product-by-category-report />
</template>

<script>
import ProductByCategoryReport from '@/components/pages/product/ProductByCategoryReport'

export default {
  components: {
    ProductByCategoryReport
  }
}
</script>
